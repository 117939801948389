<template>
  <v-container>
    <v-row>
      <v-col>
        <go-back-btn v-if="!fromSeeAllDataCliente" :routeToGo="routeToGo" />
        <PageHeader
          v-if="!fromSeeAllDataCliente"
          :title="this.advancedUsers ? titleAvanzado : titlebasicos"
          class="mb-3"
        />
        <span v-else class="primary--text">
          {{ this.advancedUsers ? titleAvanzado : titlebasicos }}
        </span>
        <!-- Data table -->
        <v-card>
          <v-data-table
            class="elevation-2"
            :headers="headers"
            :items="registrosUsers"
            :loading="loading"
            item-key="clientexTipoIsId"
            no-data-text="No hay datos disponibles para mostrar"
            :search="searchConsulta"
            :hide-default-footer="fromSeeAllDataCliente"
          >
            <template v-slot:[`item.tipoId.bool`]="{ item }">
              <v-icon
                v-if="item.tipoId.bool"
                small
                color="primary"
                class="d-flex justify-center"
              >
                {{ checkIcon }}
              </v-icon>
            </template>
            <template v-slot:[`item.credencial`]="{ item }">
              <tr
                v-for="(line, index) in item.credencial.split('\n')"
                :key="index"
              >
                <td>{{ line }}</td>
              </tr>
            </template>
            <template v-slot:top>
              <v-toolbar flat>
                <v-row>
                  <v-col cols="10" align-self="center" class="pt-5">
                    <v-text-field
                      v-model="searchConsulta"
                      clearable
                      label="Buscar"
                      single-line
                      :append-icon="iconoSearch"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="2"
                    align="end"
                    class="pt-5"
                    v-if="canCreateBasico || canCreateAvanzado"
                  >
                    <v-btn
                      color="primary"
                      @click="openModal()"
                      class="to-right"
                    >
                      Nuevo
                    </v-btn>
                  </v-col>
                </v-row>
              </v-toolbar>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip left v-if="canEditBasico || canEditAvanzado">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    @click="openModal(item)"
                  >
                    {{ editIcon }}
                  </v-icon>
                </template>
                <span>Editar usuario</span>
              </v-tooltip>
              <v-tooltip left v-if="canDeleteBasico || canDeleteAvanzado">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    @click="deleteUser(item)"
                  >
                    {{ deleteIcon }}
                  </v-icon>
                </template>
                <span>Eliminar usuario</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog
      v-if="openModalUsers && !fromSeeAllDataCliente"
      v-model="openModalUsers"
      max-width="40%"
      @keydown.esc="closeAndReload"
      persistent
    >
      <EditUsuario
        :idReg="idReg"
        :recibeId="recibeId"
        :registrosUsers="registrosUsers"
        :registrosParams="registrosParams"
        :regClientes="regClientes"
        :advancedUsers="advancedUsers"
        @closeAndReload="closeAndReload"
      ></EditUsuario>
    </v-dialog>
    <DeleteDialog
      :titleProp="titleDelete"
      :openDelete.sync="showDeleteModal"
      @onDeleteItem="confirmDelete()"
    />
  </v-container>
</template>
<script>
import PageHeader from "@/components/ui/PageHeader.vue";
import { mapActions } from "vuex";
import rules from "@/utils/helpers/rules";
import { mask } from "vue-the-mask";
import enums from "@/utils/enums/index.js";
import enumsAdmin from "@/utils/enums/index.js";
import GoBackBtn from "@/components/shared/GoBackBtn";
import EditUsuario from "@/views/EditUsuario.vue";
import DeleteDialog from "@/components/shared/DeleteDialog";

export default {
  directives: { mask },
  props: {
    fromSeeAllDataCliente: {
      type: Boolean,
      default: false,
    },
    clienteIdProp: {
      type: Number,
      default: null,
    },
    userAdvancedProp: {
      type: Boolean,
      default: null,
    },
  },
  components: { PageHeader, GoBackBtn, EditUsuario, DeleteDialog },
  name: "UsersBasicsYAvan",
  data() {
    return {
      title: "",
      titleDelete: "¿Eliminar usuario?",
      showDeleteModal: false,
      isFormValid: false,
      loading: false,
      routeToGo: "/clientes",
      editIcon: enums.icons.EDIT,
      deleteIcon: enums.icons.DELETE,
      user: enums.icons.USER,
      rules: rules,
      menu1: false,
      menu2: false,
      fechaDesde: null,
      fechaDesdeSelected: null,
      fechaHasta: null,
      fechaHastaSelected: null,
      searchConsulta: null,
      iconoSearch: enums.icons.SEARCH_ICON,
      checkIcon: enums.icons.CHECK_OUTLINE,
      headers: [],
      openModalUsers: false,
      registrosUsers: [],
      regClientes: null,
      advancedUsers: false,
      titleAvanzado: "Usuarios avanzados",
      titlebasicos: "Usuarios básicos",
      idToDelete: null,
      recibeId: null,
      registrosParams: {},
      allowedActions: null,
      canCreateBasico: false,
      canEditBasico: false,
      canDeleteBasico: false,
      canCreateAvanzado: false,
      canEditAvanzado: false,
      canDeleteAvanzado: false,
      opciones: null,
    };
  },
  async created() {
    if (this.fromSeeAllDataCliente) {
      this.idReg = this.clienteIdProp;
      this.advancedUsers = this.userAdvancedProp;
      this.loadUsuarios();
    } else {
      await this.$store.dispatch(
        "user/updateFrequencyByOptionCode",
        this.$route.params.parentOptionCode
      );
      this.setPermisos();
      this.$store.dispatch("user/changeAppLoading", {
        status: false,
        text: "",
        opaque: false,
      });
      this.regClientes = this.$route.params.itemsClientes;
      this.idReg = this.regClientes.clienteId;
      this.advancedUsers = this.$route.params.userAvanzados;
      this.loadUsuarios();
    }
  },
  methods: {
    ...mapActions({
      getUsers: "clientesData/getUsers",
      deleteUsuario: "clientesData/deleteUsuario",
      setAlert: "user/setAlert",
    }),
    setPermisos() {
      this.allowedActions = JSON.parse(localStorage.getItem("allowedActions"));
      this.allowedActions?.forEach((x) => {
        switch (x.optionCode) {
          case enumsAdmin.modules.adminSistema.permissions.NUEVO_USER_BASICO:
            this.canCreateBasico = true;
            break;
          case enumsAdmin.modules.adminSistema.permissions.EDIT_USER_BASICO:
            this.canEditBasico = true;
            break;
          case enumsAdmin.modules.adminSistema.permissions.DELETE_USER_BASICO:
            this.canDeleteBasico = true;
            break;
          case enumsAdmin.modules.adminSistema.permissions.NUEVO_USER_AVANZADO:
            this.canCreateAvanzado = true;
            break;
          case enumsAdmin.modules.adminSistema.permissions.EDIT_USER_AVANZADO:
            this.canEditAvanzado = true;
            break;
          case enumsAdmin.modules.adminSistema.permissions.DELETE_USER_AVANZADO:
            this.canDeleteAvanzado = true;
            break;
          default:
            break;
        }
      });
    },
    openModal(item) {
      this.openModalUsers = true;
      this.registrosParams = item;
    },
    closeAndReload() {
      this.openModalUsers = false;
      this.loadUsuarios();
    },
    async loadUsuarios() {
      this.loading = true;
      const response = await this.getUsers({
        clienteId: this.idReg,
        verUsuariosBasicos: !this.advancedUsers,
      });
      this.registrosUsers = response;

      if (this.fromSeeAllDataCliente && !this.advancedUsers) {
        this.headers = [
          { text: "Nombre", sortable: false, value: "tipoId.value" },
          {
            text: "Detalle",
            align: "start",
            sortable: false,
            value: "credencial",
          },
        ];
      } else if (this.fromSeeAllDataCliente && this.advancedUsers) {
        this.headers = [
          { text: "Nombre", sortable: false, value: "tipoId.value" },
          {
            text: "Es usuario basico",
            sortable: false,
            value: "tipoId.bool",
            align: "center",
          },
          {
            text: "Detalle",
            align: "start",
            sortable: false,
            value: "credencial",
          },
        ];
      } else if (this.advancedUsers) {
        this.headers = [
          { text: "Nombre", sortable: false, value: "tipoId.value" },
          {
            text: "Es usuario basico",
            sortable: false,
            value: "tipoId.bool",
            align: "center",
          },
          {
            text: "Detalle",
            align: "start",
            sortable: false,
            value: "credencial",
          },
          { text: "Acciones", value: "actions", sortable: false, align: "end" },
        ];
      } else {
        this.headers = [
          { text: "Nombre", sortable: false, value: "tipoId.value" },
          {
            text: "Detalle",
            align: "start",
            sortable: false,
            value: "credencial",
          },
          { text: "Acciones", value: "actions", sortable: false, align: "end" },
        ];
      }
      this.loading = false;
      this.recibeId = this.registrosUsers.find((x) => x.clientexTipoIsId);
    },
    deleteUser(item) {
      this.showDeleteModal = true;
      this.idToDelete = item.clientexTipoIsId;
    },
    async confirmDelete() {
      const response = await this.deleteUsuario(this.idToDelete);
      if (response.status === 200) {
        this.showDeleteModal = false;
        this.setAlert({ type: "success", message: "Eliminado con éxito." });
        this.loadUsuarios();
      }
    },
  },
};
</script>
<style></style>
