<template>
  <v-card>
    <v-container>
      <v-card-title>
        <span class="pl-1 primary--text">{{ formEditTitle }}</span>
      </v-card-title>
      <v-card-text class="pb-0">
        <v-form
          v-model="isFormValid"
          ref="form-edit-usuario"
          form="form-edit-usuario"
          id="form-edit-usuario"
          @submit.prevent="saveEdit()"
        >
          <v-row>
            <!-- Tipo de usuario -->
            <v-col cols="12" sm="6" md="12" class="py-0">
              <v-autocomplete
                v-model="usuarioSelected"
                :items="tiposUsers"
                :disabled="registrosParams != null"
                :filled="registrosParams != null"
                item-text="value"
                item-value="id"
                label="Tipo de usuarios"
                :rules="[...rules.required]"
                outlined
                clearable
                autocomplete="off"
                return-object
                dense
              >
              </v-autocomplete>
            </v-col>
            <!-- Tipo de texto -->
            <v-col cols="12" md="12" class="py-0">
              <v-textarea
                v-model="tipoTexto"
                :rules="[...rules.required]"
                label="Ingresar usuarios y contraseñas"
                dense
                auto-grow
                rows="2"
                outlined
              ></v-textarea>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined @click="closeModal"> Cancelar </v-btn>
        <v-btn
          type="submit"
          :disabled="!isFormValid"
          :loading="loadingSaveBtn"
          form="form-edit-usuario"
          color="primary"
        >
          Guardar
        </v-btn>
      </v-card-actions>
    </v-container>
  </v-card>
</template>
<script>
import rules from "@/utils/helpers/rules";
import { mapActions } from "vuex";
export default {
  name: "EditUsuario",
  // directives: { mask },
  props: {
    advancedUsers: {
      Type: Boolean,
      required: true,
    },
    registrosParams: {
      Type: Object,
      required: false,
    },
    idReg: {
      Type: Number,
    },
  },
  data: () => ({
    formEditTitle: "",
    rules: rules,
    isFormValid: false,
    conceptoNom: null,
    usuarioSelected: null,
    tiposUsers: [],
    esVigente: true,
    tipoTexto: null,
    signo: null,
    loadingSaveBtn: false,
    propId: null,
  }),
  created() {
    //trae los Tipos de usuarios en el select
    if (!this.advancedUsers) {
      this.setSelectBasicos();

      if (this.registrosParams) {
        this.setUsuariosBasicos();
      } else {
        this.newUserBasico();
      }
    } else {
      this.setSelectAvanzados();
      if (this.registrosParams) {
        this.setUsuariosAvanzados();
      } else {
        this.newUserAdvanced();
      }
    }
  },
  methods: {
    ...mapActions({
      getTipoUsuarios: "clientesData/getTipoUsuarios",
      saveUsuarios: "clientesData/saveUsuarios",
      setAlert: "user/setAlert",
    }),
    newUserAdvanced() {
      this.formEditTitle = "Nuevo usuario avanzado";
    },
    newUserBasico() {
      this.formEditTitle = "Nuevo usuario básico";
    },
    async setSelectAvanzados() {
      const tipoUserAvanzado = await this.getTipoUsuarios();
      //const usuariosAvan = tipoUserAvanzado.filter((x) => x.bool === false);
      this.tiposUsers = tipoUserAvanzado.map((x) => ({
        id: x.id,
        value: x.bool?x.value+" (Basico)":x.value,
        bool: x.bool,
      }));
    },
    async setSelectBasicos() {
      const tipoUserBasico = await this.getTipoUsuarios();
      const usuariosBasics = tipoUserBasico.filter((x) => x.bool === true);
      this.tiposUsers = usuariosBasics.map((x) => ({
        id: x.id,
        value: x.value,
        bool: x.bool,
      }));
    },
    async setUsuariosBasicos() {
      this.formEditTitle = "Editar usuario básico";
      (this.usuarioSelected = this.registrosParams.tipoId),
        (this.tipoTexto = this.registrosParams.credencial);
    },
    async setUsuariosAvanzados() {
      this.formEditTitle = "Editar usuario avanzado";
      (this.usuarioSelected = this.registrosParams.tipoId),
        (this.tipoTexto = this.registrosParams.credencial);
    },
    async saveEdit() {
      this.loadingSaveBtn = true;
      const data = {
        clientexTipoIsId: this.registrosParams?.clientexTipoIsId ?? null,
        clienteId: this.idReg,
        tipoIsId: this.usuarioSelected.id,
        credencial: this.tipoTexto,
      };
      try {
        const response = await this.saveUsuarios(data);
        if (response.status === 200) {
          this.loadingSaveBtn = false;
          this.closeModal();
          this.setAlert({ type: "success", message: "Guardado con éxito." });
        }
      } catch (error) {
        this.loadingSaveBtn = false;
        this.usuarioSelected = null;
        this.tipoTexto = null;
      }
    },
    closeModal() {
      this.$emit("closeAndReload");
    },
  },
};
</script>

<style scoped>
/* ::v-deep label {
  margin-bottom: 0;
}
::v-deep .theme--light.v-input--is-disabled input,
.theme--light.v-input--is-disabled textarea {
  color: rgb(56, 56, 56);
}
::v-deep .v-select__selection,
.v-select__selection--comma,
.v-select__selection--disabled input {
  color: black !important;
}

::v-deep .v-input__slot {
  cursor: context-menu !important;
} */

/* ::v-deep label {
  margin-bottom: 0;
}
::v-deep .theme--light.v-input--is-disabled textarea {
  color: rgb(56, 56, 56, 1);
} */
</style>
